import React from 'react';
import ContactSection from '../components/Contact';

export default function Contacto() {
  return (
    <>
      <ContactSection />
    </>
  );
}
