import React from 'react';
import Gallery from '../components/Gallery';

export default function Portfolio() {
  return (
    <div>
      <Gallery />
    </div>
  );
}
