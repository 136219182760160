import React from 'react';
import HeroImg from '../assets/images/hero.png';
import HeroSection from '../components/HeroSection';

export default function Inicio() {
  return (
    <div>
      <HeroSection />
    </div>
  );
}
